import { Observable, BehaviorSubject } from 'rxjs';
import { MessageCenter, doLog } from '../event';
import * as socketStatus from '../const';
import { BYTE } from '../const';
function SocketteWebsocket(opt = {
    url: '',
    input: null,
}) {
    const { url, input } = opt;
    const connectionStatus = new BehaviorSubject(socketStatus.WEBSOCKET_INIT);
    const messages = new Observable((observer) => {
        let inputSubscription;
        let open = false;
        let forcedClose = false;
        const closed = () => {
            if (!open) {
                return;
            }
            connectionStatus.next(socketStatus.WEBSOCKET_CLOSED);
            open = false;
        };
        connectionStatus.next(socketStatus.WEBSOCKET_OPENING);
        const scadaMap = {}; // 监控数据
        const ws = new WebSocket(url);
        ws.onopen = () => {
            open = true;
            connectionStatus.next(socketStatus.WEBSOCKET_OPENED);
            inputSubscription = input?.subscribe((data) => {
                ws.send(data);
            });
        };
        ws.onmessage = (message) => {
            // add by tdd
            try {
                const res = JSON.parse(message.data);
                const { cmd, printer, taskID = '', status, taskStatus, printStatus = [] } = res;
                const listenData = {
                    dataSource: BYTE,
                    taskID,
                    data: res,
                    printer,
                };
                // 1. 任务提交成功 2. 打印成功
                if (cmd === 'notifyPrintResult' && taskStatus === 'printed') {
                    listenData.success = true;
                    listenData.cmd = 'notifyPrintResult';
                }
                if ((cmd === 'print' && status === 'failed') ||
                    (cmd === 'notifyPrintResult' && taskStatus === 'failed')) {
                    listenData.success = false;
                    listenData.cmd = 'notifyPrintError';
                    listenData.error = {
                        code: '-',
                        message: printStatus?.[0]?.msg,
                    };
                }
                MessageCenter.listen(listenData);
                // 日志上报
                if (cmd === 'print' || cmd === 'notifyPrintResult') {
                    // 任务提交成功，创建scada
                    if (cmd === 'print' && status === 'success') {
                        const task = MessageCenter.getTaskById?.(taskID) || {}; // 一个打印任务只能取一次
                        console.log(`cnprint/${BYTE} onmessage`, task);
                        task.taskSentTime = +new Date();
                        task.taskSentTimeCost = task.taskSentTime - task.sendDataToClientTime; // 打印任务提交时间：前端下发任务给打印客户端 - 打印客户端任务提交成功;
                        scadaMap[taskID] = task;
                    }
                    const scada = scadaMap[taskID];
                    // 打印成功，更新scada
                    if (cmd === 'notifyPrintResult' &&
                        taskStatus === 'printed' &&
                        scada &&
                        !scada.printedTime) {
                        scada.printedTime = +new Date();
                        scada.printedTimeCost = scada.printedTime - scada.taskSentTimeCost; // 出纸时间：出纸成功时间 - 任务提交成功时间
                    }
                    const logInfo = {
                        traceId: scada.traceId || taskID,
                        dataSource: BYTE,
                        process: 'FRONT_END',
                        action: 'CN_PRINT_RESULT',
                        accountNo: '',
                        accountName: '',
                        bizCode: '',
                        docType: 'WAY_BILL',
                        printNode: 'NORMAL',
                        status: status || listenData.success ? 'success' : 'failed',
                        request: {
                            requestTaskId: scada?.requestTaskId,
                        },
                        response: res,
                        errorCode: listenData.error?.code,
                        errorMessage: listenData.error?.message,
                        pageUrl: window.location.href,
                    };
                    // 打印成功，上报scada，删除scada
                    if (cmd === 'notifyPrintResult' && taskStatus === 'printed' && scada) {
                        logInfo.request = {
                            ...logInfo.request,
                            ...scada,
                            timeCost: scada.printedTime - scada.requestStartTime, // 打印总时长：出纸成功时间 - 请求开始时间
                        };
                        delete scadaMap[taskID];
                    }
                    // 渲染失败、打印失败，删除scada
                    if (cmd === 'notifyPrintResult' && taskStatus === 'failed') {
                        delete scadaMap[taskID];
                    }
                    doLog(logInfo);
                }
            }
            catch (e) {
                console.log(e);
            }
            observer.next(message.data);
        };
        ws.onclose = (e) => {
            // prevent observer.complete() being called after observer.error(...)
            if (!open) {
                return;
            }
            closed();
            if (forcedClose) {
                connectionStatus.next(socketStatus.WEBSOCKET_COMPLETE);
                observer.complete();
            }
            else {
                connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
                observer.error(new Error(e.reason));
            }
        };
        ws.onerror = (err) => {
            closed();
            connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
            observer.error(err);
        };
        return () => {
            forcedClose = true;
            if (inputSubscription) {
                inputSubscription.unsubscribe();
            }
            if (open) {
                closed();
                ws.close();
            }
        };
    });
    return { messages, connectionStatus };
}
export default SocketteWebsocket;
