export const WEBSOCKET_INIT = 0;
export const WEBSOCKET_OPENING = 1;
export const WEBSOCKET_OPENED = 2;
export const WEBSOCKET_CLOSED = 4;
export const WEBSOCKET_RECONNECTING = 5;
export const WEBSOCKET_ERROR = 6;
export const WEBSOCKET_COMPLETE = 7;
export const WEBSOCKET_MAX_ATTEMPTS = 8;
export const CAINIAO = 'CAINIAO';
export const PDD = 'PDD';
export const JD = 'JD';
export const BYTE = 'BYTE';
export const KUAISHOU = 'KUAISHOU';
export const VIP = 'VIP';
export const HUAWEI = 'HUAWEI';
export const XHS = 'XHS';
export const XHSV2 = 'XHSV2';
export const WXSPH = 'WXSPH';
export const DW = 'DW'; // 得物
export const AKC = 'AKC'; // 爱库存/梦饷
export const NAMEMAPINSTANCE = {
    CAINIAO: {
        instance: 'wetPrintInstance',
        ready: 'isWetReady',
        need: 'isNeedWetPrint',
        url: 'wetDebuggerUrl',
        unListen: 'unsubscribeWet',
    },
    CLOUD: {
        instance: 'cloudPrintInstance',
        ready: 'isCloudReady',
        need: 'isNeedCloudPrint',
        url: 'cloudDebuggerUrl',
        unListen: 'unsubscribeCloud',
    },
    PDD: {
        instance: 'pddPrintInstance',
        ready: 'isPddReady',
        need: 'isNeedPddPrint',
        url: 'pddDebuggerUrl',
        unListen: 'unsubscribePdd',
    },
    JD: {
        instance: 'jdPrintInstance',
        ready: 'isJdReady',
        need: 'isNeedJdPrint',
        url: 'jdDebuggerUrl',
        unListen: 'unsubscribeJd',
    },
    BYTE: {
        instance: 'bytePrintInstance',
        ready: 'isByteReady',
        need: 'isNeedBytePrint',
        url: 'byteDebuggerUrl',
        unListen: 'unsubscribeByte',
    },
    KUAISHOU: {
        instance: 'kuaiShouPrintInstance',
        ready: 'isKuaiShouReady',
        need: 'isNeedKuaiShouPrint',
        url: 'kuaiShouDebuggerUrl',
        unListen: 'unsubscribeKuaiShou',
    },
    VIP: {
        instance: 'vipPrintInstance',
        ready: 'isVipReady',
        need: 'isNeedVipPrint',
        url: 'vipDebuggerUrl',
        unListen: 'unsubscribeVip',
    },
    HUAWEI: {
        instance: 'huaWeiPrintInstance',
        ready: 'isHuaWeiReady',
        need: 'isNeedHuaWeiPrint',
        url: 'huaWeiDebuggerUrl',
        unListen: 'unsubscribeHuaWei',
    },
    XHS: {
        instance: 'xhsPrintInstance',
        ready: 'isXhsReady',
        need: 'isNeedXhsPrint',
        url: 'xhsDebuggerUrl',
        unListen: 'unsubscribeXhs',
    },
    XHSV2: {
        instance: 'xhsv2PrintInstance',
        ready: 'isXhsv2Ready',
        need: 'isNeedXhsv2Print',
        url: 'xhsv2DebuggerUrl',
        unListen: 'unsubscribeXhsv2',
    },
    WXSPH: {
        instance: 'wxsphPrintInstance',
        ready: 'isWxsphReady',
        need: 'isNeedWxsphPrint',
        url: 'wxsphDebuggerUrl',
        unListen: 'unsubscribeWxsph',
    },
    DW: {
        instance: 'dwPrintInstance',
        ready: 'isDwReady',
        need: 'isNeedDwPrint',
        url: 'dwsDebuggerUrl',
        unListen: 'unsubscribeDw',
    },
    AKC: {
        instance: 'akcPrintInstance',
        ready: 'isAkcReady',
        need: 'isNeedAkcPrint',
        url: 'akcDebuggerUrl',
        unListen: 'unsubscribeAkc',
    },
};
export const PRINT_CONFIG = {
    [DW]: {
        socketUrl: 'ws://127.0.0.1:23825',
        name: '得物打印',
    },
    [AKC]: {
        socketUrl: 'ws://localhost:2750',
        name: '爱库存打印',
    },
};
export const STATUS_NOT_CONNECTED = 0;
export const STATUS_CONNECTED = 1;
export const STATUS_RECONNECT = 2;
export const STATUS_ERROR = -1;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (_p) => undefined;
