import $i18n from '@alife/panda-i18n';
import Print from './cn-print';
import PddPrint from './pdd-print';
import JdPrint from './jd-print';
import BytePrint from './byte-print';
import CloudPrint from './cloud-print';
import KuaiShouPrint from './kuaishou-print';
import VipPrint from './vip-print';
import HuaWeiPrint from './huawei-print';
import XhsPrint from './xhs-print';
import Xhsv2Print from './xhs-print-v2';
import WxsphPrint from './wxsph-print';
import DwPrint from './dw-print';
import AkcPrint from './akc-print';
import { COMMENDCONFIG, MessageCenter, doLog, EventManager } from './event';
import { CAINIAO, PDD, JD, BYTE, KUAISHOU, NAMEMAPINSTANCE, VIP, HUAWEI, XHS, XHSV2, WXSPH, DW, AKC, } from './const';
import { isAndroid } from 'src/utils';
class CnPrint {
    // wet打印实例
    static wetPrintInstance = null;
    // pdd打印实例
    static pddPrintInstance = null;
    // JD打印实例
    static jdPrintInstance = null;
    // 抖音打印实例
    static bytePrintInstance = null;
    // 云打印实例
    static cloudPrintInstance = null;
    // 快手打印实例
    static kuaiShouPrintInstance = null;
    // 唯品会打印实例
    static vipPrintInstance = null;
    // 华为商城打印实例
    static huaWeiPrintInstance = null;
    // 小红书打印实例
    static xhsPrintInstance = null;
    // 小红书新版打印实例
    static xhsv2PrintInstance = null;
    // 微信视频号
    static wxsphPrintInstance = null;
    // 得物
    static dwPrintInstance = null;
    // 爱库存
    static akcPrintInstance = null;
    url = '';
    config = {};
    addEventListen;
    doLog;
    printData;
    getPrintConfigNameList;
    // 是否启用wet打印
    isNeedWetPrint = true;
    // 是否启用云打印
    isNeedCloudPrint = false;
    // 是否启用拼多多打印
    isNeedPddPrint = false;
    // 是否启用京东打印
    isNeedJdPrint = false;
    // 是否启用抖音打印
    isNeedBytePrint = false;
    // 是否启用快手打印
    isNeedKuaiShouPrint = false;
    // 是否启用唯品会打印
    isNeedVipPrint = false;
    // 是否启用华为商城打印
    isNeedHuaWeiPrint = false;
    // 是否启用小红书打印
    isNeedXhsPrint = false;
    // 是否启用小红书新版打印
    isNeedXhsv2Print = false;
    // 是否启用微信视频号
    isNeedWxsphPrint = false;
    // 是否启用得物
    isNeedDwPrint = false;
    // 是否启用爱库存
    isNeedAkcPrint = false;
    // wet监听
    unsubscribeWet = null;
    // pdd监听
    unsubscribePdd = null;
    // 京东监听
    unsubscribeJd = null;
    // 抖音监听
    unsubscribeByte = null;
    // 云监听
    unsubscribeCloud = null;
    // 快手
    unsubscribeKuaiShou = null;
    // 唯品会
    unsubscribeVip = null;
    // 华为商城
    unsubscribeHuaWei = null;
    // 小红书
    unsubscribeXhs = null;
    // 小红书新版
    unsubscribeXhsv2 = null;
    // 微信视频号
    unsubscribeWxsph = null;
    // 得物
    unsubscribeDw = null;
    // 爱库存
    unsubscribeAkc = null;
    // pdd debugger url
    pddDebuggerUrl = '';
    // jd debugger url
    jdDebuggerUrl = '';
    // wet debugger url
    wetDebuggerUrl = '';
    // cloud  debugger url
    cloudDebuggerUrl = '';
    // byte debugger url
    byteDebuggerUrl = '';
    // kuaishou debugger url
    kuaiShouDebuggerUrl = '';
    // 唯品会 debugger url
    vipDebuggerUrl = '';
    // 华为商城 debugger url
    huaWeiDebuggerUrl = '';
    // 小红书 debugger url
    xhsDebuggerUrl = '';
    // 小红书新版 debugger url
    xhsv2DebuggerUrl = '';
    // 微信视频号 debugger url
    wxsphDebuggerUrl = '';
    // 得物 debugger url
    dwDebuggerUrl = '';
    // 爱库存 debugger url
    akcDebuggerUrl = '';
    isWetReady = false;
    isPddReady = true;
    isJdReady = true;
    isByteReady = true;
    isCloudReady = true;
    isKuaiShouReady = true;
    isVipReady = true;
    isHuaWeiReady = true;
    isXhsReady = true;
    isXhsv2Ready = true;
    isWxsphReady = true;
    isDwReady = true;
    isAkcReady = true;
    isSemiAutoPackageStartReady = false;
    messageChannel;
    wetIsInit = true;
    handlerError;
    handlerClose;
    constructor(opts = {}) {
        const { isNeedWetPrint = true, isNeedCloudPrint = false, isNeedPddPrint = false, isNeedJdPrint = false, isNeedBytePrint = false, isNeedKuaiShouPrint = false, isNeedVipPrint = false, isNeedHuaWeiPrint = false, isNeedXhsPrint = false, isNeedXhsv2Print = false, isNeedWxsphPrint = false, isNeedDwPrint = false, isNeedAkcPrint = false, messageChannel = () => { }, pddDebuggerUrl = '', wetDebuggerUrl = '', jdDebuggerUrl = '', byteDebuggerUrl = '', cloudDebuggerUrl = '', kuaiShouDebuggerUrl = '', vipDebuggerUrl = '', huaWeiDebuggerUrl = '', xhsDebuggerUrl = '', xhsv2DebuggerUrl = '', wxsphDebuggerUrl = '', dwDebuggerUrl = '', akcDebuggerUrl = '', } = opts;
        this.isNeedWetPrint = isNeedCloudPrint ? false : isNeedWetPrint; // 云打印启动的话WET就停用
        this.isNeedCloudPrint = isNeedCloudPrint;
        this.isNeedPddPrint = isNeedPddPrint;
        this.isNeedJdPrint = isNeedJdPrint;
        this.isNeedBytePrint = isNeedBytePrint;
        this.isNeedKuaiShouPrint = isNeedKuaiShouPrint;
        this.isNeedVipPrint = isNeedVipPrint;
        this.isNeedHuaWeiPrint = isNeedHuaWeiPrint;
        this.isNeedXhsPrint = isNeedXhsPrint;
        this.isNeedXhsv2Print = isNeedXhsv2Print;
        this.isNeedWxsphPrint = isNeedWxsphPrint;
        this.isNeedDwPrint = isNeedDwPrint;
        this.isNeedAkcPrint = isNeedAkcPrint;
        this.messageChannel = messageChannel;
        this.pddDebuggerUrl = pddDebuggerUrl;
        this.wetDebuggerUrl = wetDebuggerUrl;
        this.jdDebuggerUrl = jdDebuggerUrl;
        this.byteDebuggerUrl = byteDebuggerUrl;
        this.cloudDebuggerUrl = cloudDebuggerUrl;
        this.kuaiShouDebuggerUrl = kuaiShouDebuggerUrl;
        this.vipDebuggerUrl = vipDebuggerUrl;
        this.huaWeiDebuggerUrl = huaWeiDebuggerUrl;
        this.xhsDebuggerUrl = xhsDebuggerUrl;
        this.xhsv2DebuggerUrl = xhsv2DebuggerUrl;
        this.wxsphDebuggerUrl = wxsphDebuggerUrl;
        this.dwDebuggerUrl = dwDebuggerUrl;
        this.akcDebuggerUrl = akcDebuggerUrl;
        this.wetIsInit = true;
        this.init();
        EventManager.Instance.supposeCloud = isNeedCloudPrint;
    }
    init() {
        Object.keys(NAMEMAPINSTANCE).forEach((key) => {
            const { url, need, instance, ready, unListen } = NAMEMAPINSTANCE[key];
            const tmpUrl = (this[url] || '');
            const isNeed = this[need];
            if (isNeed) {
                const opt = {};
                if (tmpUrl) {
                    opt.url = tmpUrl;
                }
                if (CnPrint[instance] && CnPrint[instance] !== null) {
                    CnPrint[instance].isReady = true;
                }
                else if (key === CAINIAO) {
                    CnPrint[instance] = new Print(opt);
                }
                else if (key === 'CLOUD') {
                    CnPrint[instance] = new CloudPrint(opt);
                }
                else if (key === PDD) {
                    CnPrint[instance] = new PddPrint(opt);
                }
                else if (key === JD) {
                    CnPrint[instance] = new JdPrint(opt);
                }
                else if (key === BYTE) {
                    CnPrint[instance] = new BytePrint(opt);
                }
                else if (key === KUAISHOU) {
                    CnPrint[instance] = new KuaiShouPrint(opt);
                }
                else if (key === VIP) {
                    CnPrint[instance] = new VipPrint(opt);
                }
                else if (key === HUAWEI) {
                    CnPrint[instance] = new HuaWeiPrint(opt);
                }
                else if (key === XHS) {
                    CnPrint[instance] = new XhsPrint(opt);
                }
                else if (key === XHSV2) {
                    CnPrint[instance] = new Xhsv2Print(opt);
                }
                else if (key === WXSPH) {
                    CnPrint[instance] = new WxsphPrint(opt);
                }
                else if (key === DW) {
                    CnPrint[instance] = new DwPrint(opt);
                }
                else if (key === AKC) {
                    CnPrint[instance] = new AkcPrint(opt);
                }
                this[unListen] = CnPrint?.[instance]?.subscribe?.((data) => {
                    const { success, error = {} } = data;
                    this[ready] = success;
                    const inst = CnPrint[instance];
                    if (key === CAINIAO && inst.isReady && this.wetIsInit && success) {
                        this.wetIsInit = false;
                        inst.input?.next?.(inst &&
                            inst?.wetStringify?.({
                                bussinessType: inst.bussinessType,
                                messageType: inst.initMessageType,
                            }));
                        inst.isReady = false;
                    }
                    // 当菜鸟的wet连接成功后，去发送指令判断是否要打开半自动打包
                    if (key === CAINIAO && !isAndroid) {
                        if (!this.isSemiAutoPackageStartReady) {
                            inst?.input?.next?.(inst?.wetStringify?.({
                                bussinessType: inst.semiAutomaticPackagingBussinessType,
                                messageType: inst.semiAutomaticPackagingMessageType,
                            }));
                            this.isSemiAutoPackageStartReady = true;
                        }
                        if (success && data?.data) {
                            const dt = JSON.parse(data?.data);
                            if (dt?.messageType === 7) {
                                const semiAutomaticPackagingStartFlag = dt?.semiAutoPackageStartFlag || 0;
                                inst.semiAutomaticPackagingStartFlag = semiAutomaticPackagingStartFlag;
                                console.log('semiAutomaticPackagingStartFlag', semiAutomaticPackagingStartFlag);
                            }
                        }
                    }
                    if (!success) {
                        const message = {
                            data: { cmd: 'connectError' },
                            dataSource: key,
                            error,
                        };
                        MessageCenter.listen(message);
                        // 因为大宝上报太频繁，暂时关闭连接异常的日志上报
                        // try {
                        // const logParams = {
                        //   status: 'failed',
                        //   process: 'FRONT_END',
                        //   action: 'WebSocketDisconnect',
                        //   request: message,
                        // };
                        // doLog(logParams);
                        // } catch (e) {
                        // do nothing
                        // }
                    }
                    this.messageChannel(data);
                });
            }
        });
        this._initMethods();
    }
    // 触发打印连接异常
    triggerNotifyPrintError(dataSource, item = {}) {
        let res = {};
        if (dataSource === CAINIAO) {
            let taskID;
            let printer;
            let traceId;
            let bizCode;
            if (EventManager.Instance.supposeCloud) {
                const { task = {} } = item;
                taskID = task.taskID;
                printer = task.printer;
                traceId = item.traceId;
                bizCode = item.bizCode;
            }
            else {
                const { data = {} } = item;
                const task = data.task || {};
                taskID = task.taskID;
                printer = task.printer;
                traceId = item.data?.traceId;
                bizCode = item.data?.bizCode;
            }
            res = {
                dataSource,
                cmd: 'notifyPrintError',
                errorMessage: $i18n.get({
                    id: 'WETConnectionIsAbnormalPleaseCheck_1974499709',
                    dm: 'WET连接异常，请检查是否有漏打情况，刷新浏览器并进行补打',
                    ns: 'CnPrint',
                }),
                ...item,
                traceId,
                taskID,
                printer,
                bizCode,
            };
        }
        else {
            const { key = '', parameters = {}, task = {} } = item;
            const { printName = '' } = parameters;
            const { taskID = '', printer = '' } = task;
            const tmpPrintName = dataSource.toLowerCase();
            res = {
                dataSource,
                errorMessage: $i18n.get({
                    id: 'TmpPrintNameConnectionIsAbnormalPleaseCheck_1717256960',
                    dm: '{tmpPrintName}连接异常，请检查是否有漏打情况，刷新浏览器并进行补打',
                    ns: 'CnPrint',
                }, { tmpPrintName }),
                printStatus: [
                    {
                        msg: $i18n.get({
                            id: 'TmpPrintNameConnectionIsAbnormalPleaseCheck_1717256960',
                            dm: '{tmpPrintName}连接异常，请检查是否有漏打情况，刷新浏览器并进行补打',
                            ns: 'CnPrint',
                        }, { tmpPrintName }),
                    },
                ],
                ...item,
                cmd: 'notifyPrintError',
                taskID: key || taskID,
                printer: printName || printer,
            };
        }
        MessageCenter.listen(res);
    }
    checkConnect(dataSource, data) {
        if (dataSource === PDD && CnPrint.pddPrintInstance) {
            if (!this.isPddReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === JD && CnPrint.jdPrintInstance) {
            if (!this.isJdReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === BYTE && CnPrint.bytePrintInstance) {
            if (!this.isByteReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === KUAISHOU && CnPrint.kuaiShouPrintInstance) {
            if (!this.isKuaiShouReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === VIP && CnPrint.vipPrintInstance) {
            if (!this.isVipReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === HUAWEI && CnPrint.huaWeiPrintInstance) {
            if (!this.isHuaWeiReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === CAINIAO) {
            if (CnPrint.wetPrintInstance && !this.isWetReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
            if (CnPrint.cloudPrintInstance && !this.isCloudReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === XHS && CnPrint.xhsPrintInstance) {
            if (!this.isXhsReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === XHSV2 && CnPrint.xhsv2PrintInstance) {
            if (!this.isXhsv2Ready) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === WXSPH && CnPrint.wxsphPrintInstance) {
            if (!this.isWxsphReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === DW && CnPrint.dwPrintInstance) {
            if (!this.isDwReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        if (dataSource === AKC && CnPrint.akcPrintInstance) {
            if (!this.isAkcReady) {
                this.triggerNotifyPrintError(dataSource, data);
                return false;
            }
        }
        return true;
    }
    /**
     * add by tdd at  2020-11-27
     * 通用方法注入
     */
    _initMethods() {
        // [getPrinters， getPrinterConfig, setPrinterConfig,
        // printData, getTaskStatus,getGlobalConfig, setGlobalConfig,
        // getAgentInfo, getConfigName, getPrintConfigNameList ]
        Object.keys(COMMENDCONFIG).forEach((cell) => {
            const method = COMMENDCONFIG[cell];
            this[cell] = (arg) => {
                const result = method?.(arg);
                if (cell === 'printData') {
                    console.log('cnprint/printData', result, arg);
                    const { printConfig = {}, data = [] } = arg || {};
                    // 只会在复核页面用到，只取第一个,documentID值塞的是运单号
                    let documentID = '';
                    if (Array.isArray(data)) {
                        documentID = data?.length === 1 ? data[0].documentID : '';
                    }
                    else {
                        documentID = data?.documentID;
                    }
                    const { dataSource = CAINIAO, billType = 0 } = printConfig;
                    if (!this.checkConnect(dataSource, result)) {
                        try {
                            const logParams = {
                                ...printConfig,
                                action: 'CN_PRINT_WEBSOCKET_DISCONNECT',
                                pageUrl: window.location.href,
                                status: 'failed',
                                process: 'FRONT_END',
                                dataSource,
                                request: {
                                    ...printConfig,
                                },
                            };
                            doLog(logParams);
                        }
                        catch (e) {
                            console.log(e);
                        }
                        // return;
                    }
                    // 发送数据给wet 判断是否要拦截包裹
                    if (!isAndroid &&
                        documentID &&
                        billType === '1' &&
                        CnPrint?.wetPrintInstance?.semiAutomaticPackagingStartFlag === 1) {
                        CnPrint.wetPrintInstance &&
                            CnPrint.wetPrintInstance.input?.next(CnPrint.wetPrintInstance.wetStringify({
                                bussinessType: 1,
                                messageType: 8,
                                waybillNo: documentID,
                            }));
                    }
                    if (dataSource === PDD) {
                        CnPrint.pddPrintInstance &&
                            CnPrint.pddPrintInstance?.input?.next?.(JSON.stringify(result));
                    }
                    else if (dataSource === JD) {
                        CnPrint.jdPrintInstance && CnPrint.jdPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === BYTE) {
                        CnPrint.bytePrintInstance &&
                            CnPrint.bytePrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === KUAISHOU) {
                        CnPrint.kuaiShouPrintInstance &&
                            CnPrint.kuaiShouPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === VIP) {
                        CnPrint.vipPrintInstance &&
                            CnPrint.vipPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === HUAWEI) {
                        CnPrint.huaWeiPrintInstance &&
                            CnPrint.huaWeiPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (EventManager.Instance.supposeCloud) {
                        CnPrint.cloudPrintInstance &&
                            CnPrint.cloudPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === XHS) {
                        CnPrint.xhsPrintInstance &&
                            CnPrint.xhsPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === XHSV2) {
                        CnPrint.xhsv2PrintInstance &&
                            CnPrint.xhsv2PrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === WXSPH) {
                        CnPrint.wxsphPrintInstance &&
                            CnPrint.wxsphPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === DW) {
                        CnPrint.dwPrintInstance && CnPrint.dwPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else if (dataSource === AKC) {
                        CnPrint.akcPrintInstance &&
                            CnPrint.akcPrintInstance.input?.next(JSON.stringify(result));
                    }
                    else {
                        CnPrint.wetPrintInstance &&
                            CnPrint.wetPrintInstance.input?.next(CnPrint.wetPrintInstance.wetStringify(result));
                    }
                    try {
                        const { ready } = NAMEMAPINSTANCE[dataSource || CAINIAO];
                        const timeCost = +new Date() - printConfig.requestSuccessTime || 0; // 下发打印指令时长
                        const logParams = {
                            action: 'CN_PRINT_SEND_DATA_TO_CLIENT',
                            pageUrl: window.location.href,
                            status: 'success',
                            process: 'FRONT_END',
                            dataSource,
                            request: {
                                ...printConfig,
                                [ready]: this[ready],
                                timeCost,
                            },
                            resCode: printConfig.resCode,
                            resName: printConfig.resName,
                            accountNo: printConfig.accountNo,
                            accountName: printConfig.accountName,
                            bizCode: printConfig.bizCode,
                            printNode: printConfig.printNode,
                        };
                        doLog(logParams);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
                else {
                    const { data, printer } = result;
                    if (EventManager.Instance.supposeCloud) {
                        CnPrint.cloudPrintInstance &&
                            CnPrint.cloudPrintInstance.input?.next(JSON.stringify(data));
                    }
                    else {
                        CnPrint.wetPrintInstance &&
                            CnPrint.wetPrintInstance.input?.next(CnPrint.wetPrintInstance.wetStringify(result));
                    }
                    if (CnPrint.pddPrintInstance) {
                        printer && (data.printer = printer);
                        CnPrint.pddPrintInstance.input?.next(JSON.stringify(data));
                    }
                }
            };
        });
        Object.keys(MessageCenter).forEach((cell) => {
            const method = MessageCenter[cell];
            if (typeof method === 'function') {
                this[cell] = (...arg) => {
                    method.apply(this, arg);
                };
            }
        });
    }
    // 老版打印
    doPrint(data) {
        CnPrint.wetPrintInstance && CnPrint.wetPrintInstance.doPrint(data);
    }
    // 老版自定义协议打印
    customPrint(data) {
        CnPrint.wetPrintInstance && CnPrint.wetPrintInstance.printData(data);
    }
    // 获取打印协议
    getPrintAgreement(params) {
        return COMMENDCONFIG.printData(params);
    }
    /*
     * 打印日志上报
     * @printConfig {Object} 打印参数
     * */
    printLog(printConfig) {
        try {
            const logParams = {
                process: 'FRONT_END',
                action: 'CN_PRINT_SEND_DATA_TO_CLIENT',
                ...printConfig,
            };
            doLog(logParams);
        }
        catch (e) {
            // do nothing
        }
    }
    /*
     * 移除链接
     * */
    unsubscribe() {
        try {
            this.unsubscribePdd && this.unsubscribePdd();
            this.unsubscribeWet && this.unsubscribeWet();
            this.unsubscribeByte && this.unsubscribeByte();
            this.unsubscribeJd && this.unsubscribeJd();
            this.unsubscribeCloud && this.unsubscribeCloud();
            this.unsubscribeKuaiShou && this.unsubscribeKuaiShou();
            this.unsubscribeVip && this.unsubscribeVip();
            this.unsubscribeHuaWei && this.unsubscribeHuaWei();
            this.unsubscribeXhs && this.unsubscribeXhs();
            this.unsubscribeXhsv2 && this.unsubscribeXhsv2();
            this.unsubscribeWxsph && this.unsubscribeWxsph();
            this.unsubscribeDw && this.unsubscribeDw();
            this.unsubscribeAkc && this.unsubscribeAkc();
        }
        catch (e) {
            console.log(e);
        }
    }
}
export { CnPrint, doLog };
