import { Observable, BehaviorSubject } from 'rxjs';
import { MessageCenter, doLog } from '../event';
import * as socketStatus from '../const';
import { WXSPH } from '../const';
function SocketteWebsocket(opt = {
    url: '',
    input: null,
}) {
    const { url, input } = opt;
    const connectionStatus = new BehaviorSubject(socketStatus.WEBSOCKET_INIT);
    const messages = new Observable((observer) => {
        let inputSubscription;
        let open = false;
        let forcedClose = false;
        const closed = () => {
            if (!open) {
                return;
            }
            connectionStatus.next(socketStatus.WEBSOCKET_CLOSED);
            open = false;
        };
        connectionStatus.next(socketStatus.WEBSOCKET_OPENING);
        const ws = new WebSocket(url);
        ws.onopen = () => {
            open = true;
            connectionStatus.next(socketStatus.WEBSOCKET_OPENED);
            inputSubscription = input?.subscribe((data) => {
                ws.send(data);
            });
        };
        ws.onmessage = (message) => {
            try {
                const res = JSON.parse(message.data);
                const { command, printer, results = [] } = res;
                const [{ taskID }] = results;
                const listenData = {
                    dataSource: WXSPH,
                    taskID,
                    data: res,
                    printer,
                };
                // 1. 打印成功
                if (command === 'print') {
                    listenData.success = results?.every((item = {}) => item.success);
                    if (listenData.success) {
                        listenData.cmd = 'notifyPrintResult';
                    }
                    else {
                        listenData.cmd = 'notifyPrintError';
                        listenData.error = {
                            code: '-',
                            message: results?.[0]?.failureReason,
                        };
                    }
                }
                const task = MessageCenter.getTaskById?.(taskID) || {};
                MessageCenter.listen(listenData);
                // 日志上报
                if (command === 'print') {
                    console.log(`cnprint/${WXSPH} onmessage`, task);
                    const logInfo = {
                        traceId: task.traceId || taskID,
                        dataSource: WXSPH,
                        process: 'FRONT_END',
                        action: 'CN_PRINT_RESULT',
                        accountNo: '',
                        accountName: '',
                        bizCode: '',
                        docType: 'WAY_BILL',
                        printNode: 'NORMAL',
                        status: listenData.success ? 'success' : 'failed',
                        request: {
                            requestTaskId: task.requestTaskId,
                        },
                        response: res,
                        errorCode: listenData.error?.code,
                        errorMessage: listenData.error?.message,
                        pageUrl: window.location.href,
                    };
                    // 打印成功，记录监控数据
                    if (listenData.success) {
                        const printedTime = +new Date();
                        logInfo.request = {
                            ...task,
                            printedTime,
                            timeCost: printedTime - task.requestStartTime, // 打印总时长
                        };
                    }
                    doLog(logInfo);
                }
            }
            catch (e) {
                console.log(e);
            }
            observer.next(message.data);
        };
        ws.onclose = (e) => {
            // prevent observer.complete() being called after observer.error(...)
            if (!open) {
                return;
            }
            closed();
            if (forcedClose) {
                connectionStatus.next(socketStatus.WEBSOCKET_COMPLETE);
                observer.complete();
            }
            else {
                connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
                observer.error(new Error(e.reason));
            }
        };
        ws.onerror = (err) => {
            closed();
            connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
            observer.error(err);
        };
        return () => {
            forcedClose = true;
            if (inputSubscription) {
                inputSubscription.unsubscribe();
            }
            if (open) {
                closed();
                ws.close();
            }
        };
    });
    return { messages, connectionStatus };
}
export default SocketteWebsocket;
